import {Controller} from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {

    connect() {
        this.checkFlashMessage()
    }

    checkFlashMessage() {
        const message = this.data.get('message');
        let title = this.data.get('title');
        if (title === 'notice')
            title = 'info';
        if(message) {
            this.showFlash(title, message);
            this.data.set("message", '');
        }
    }

    onSuccess(event) {
        let response = event.detail[0];
        if (response.success === 'false') {
            this.showFlash("error", response.error.message);
        } else {
            if (this.data.has('success-message')) {
                this.showFlash('success', this.data.get('success-message'));
            }
        }
    }

    showFlash(icon, title) {
        Swal.fire({
            position: 'top-end',
            icon: icon,
            title: title,
            showConfirmButton: false,
            timer: 2000,
            toast: true
        })
    }
}