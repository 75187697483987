import swal from 'sweetalert2'
import Rails from '@rails/ujs'

Rails.confirm = function (message, element) {

  const swalWithBootstrap = swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-info'
    },
    buttonsStyling: true
  });

  swalWithBootstrap.fire({
    html: message,
    showCancelButton: true,
    confirmButtonText: 'Okay',
    cancelButtonText: 'Cancel'
  })
  .then((result) => {
    if (result.value) {
      element.removeAttribute('data-confirm');
      element.click();
    }
  })

};